import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'semantic-ui-react'
import { usePrismic } from '../../context/PrismicContext'
import {
  Container,
  PrimaryButton,
  StyledModalContent,
  StyledText,
} from './MaxOrderModal.styles'

const MaxOrderModal = ({ open, onClose }) => {
  const {
    prismicData: { prismicCartPage, prismicForgotPasswordPage },
  } = usePrismic()
  return (
    <Modal
      closeIcon
      style={{ width: '80%', maxWidth: 300 }}
      open={open}
      centered={true}
      closeOnDimmerClick={true}
      closeOnEscape
      onClose={onClose}
    >
      <StyledModalContent>
        <Container direction="column" padding="18px">
          {prismicCartPage.cap_total_order_message.length > 0 ? (
            <StyledText>
              {`${prismicCartPage?.cap_total_order_message[0].text}:`}
            </StyledText>
          ) : null}
          {prismicCartPage.cart_max > 0 && (
            <StyledText
              style={{ fontSize: '1.2rem', fontWeight: 'bold' }}
            >{`$${prismicCartPage.cart_max.toFixed(2)}`}</StyledText>
          )}
        </Container>
        <Container justify="flex-end">
          <PrimaryButton onClick={onClose}>
            {prismicForgotPasswordPage.close[0].text}
          </PrimaryButton>
        </Container>
      </StyledModalContent>
    </Modal>
  )
}

MaxOrderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default MaxOrderModal
