import React from 'react'
import { Button } from 'semantic-ui-react'
import { navigate } from 'gatsby'
import styled from 'styled-components'

const ThemedButton = styled(Button)`
  &&& {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    border-width: 1px;
    border-style: solid;
    background-color: transparent;
    width: 100%;
    height: 36px;
    border-radius: 18px;
    border-color: ${props =>
      props.$insidemenu
        ? props.theme.colors.white
        : props.theme.colors.metallicSeaweed};
    color: ${props =>
      props.$insidemenu
        ? props.theme.colors.white
        : props.theme.colors.metallicSeaweed};
  }
`
const ButtonText = styled.span`
  font-size: 0.9em;
`

const BecomeAmbButton = ({ $insidemenu }) => (
  <ThemedButton
    $insidemenu={$insidemenu}
    onClick={() => navigate('/enrollment')}
  >
    <ButtonText>Become a Q Sciences Ambassador</ButtonText>
  </ThemedButton>
)

export default BecomeAmbButton
