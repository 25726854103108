import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
// Components
import { Modal, Radio } from 'semantic-ui-react'
import { Primary } from '../../components'
import {
  StyledModal,
  StyledLogo,
  StyledHeader,
  Container,
  StyledText,
  StyledTextSmall,
  StyledCheck,
  Gap,
  Divider,
  AnimatedContainer,
} from './PickYourPerksAmbEnrModal.styles'
// Contexts
import { useAuthContext } from '../../context/AuthContext'
import { useCartContext } from '../../context/CartContext'
import { usePrismic } from '../../context/PrismicContext'
// Utils
import { enrollSkus } from '../../utils/constants'
import { getAmbEnrollmentFees } from '../../utils/storeHelpers'

const PickYourPerksAmbEnrModal = ({ open, onClose }) => {
  const { qUser, qUserUpline } = useAuthContext()
  const {
    ambEnrollProducts,
    setAmbEnrollProducts,
    selectedAmbEnrollSku,
    setSelectedAmbEnrollSku,
    addSelectedAmbFeeToCart,
  } = useCartContext()
  const {
    prismicData: { prismicPickYourPerksModal },
  } = usePrismic()

  const { ambPremiumSku, ambBasicSku } = enrollSkus
  const { premium, basic } = ambEnrollProducts

  const getFees = async () => {
    const { ambPremium, ambBasic } = await getAmbEnrollmentFees()
    setAmbEnrollProducts({
      premium: ambPremium,
      basic: ambBasic,
    })
  }

  useEffect(() => {
    getFees()
    setSelectedAmbEnrollSku(ambPremiumSku)
  }, [enrollSkus])

  const skuPriceMap = {
    [ambPremiumSku]: premium,
    [ambBasicSku]: basic,
  }

  const totalCost =
    skuPriceMap[selectedAmbEnrollSku]?.products?.items[0]?.price?.regularPrice
      ?.amount?.value

  const onHandleSkuChange = name => setSelectedAmbEnrollSku(name)

  const handleClose = () => {
    setSelectedAmbEnrollSku(null)
    navigate(-1)
  }

  const onSubmit = () => {
    addSelectedAmbFeeToCart()
    onClose()
  }

  return (
    <StyledModal
      closeIcon
      open={open}
      centered={true}
      closeOnDimmerClick={true}
      closeOnEscape={true}
      onClose={handleClose}
    >
      <Container direction="column">
        <StyledLogo src="https://shopq.cdn.prismic.io/shopq/06ea17c4-0c65-4563-9507-bc314540e05f_black-q-sciences.svg" />
        <Gap />
        <StyledTextSmall weight={900}>
          {prismicPickYourPerksModal.ambassador_enrollment[0].text}
        </StyledTextSmall>
        <Gap />
        {qUserUpline?.displayName.length > 0 && (
          <StyledTextSmall>{`(${prismicPickYourPerksModal.referred_by[0].text}: ${qUserUpline?.displayName})`}</StyledTextSmall>
        )}
        <Gap height="16px" />
        <StyledText>
          {`${prismicPickYourPerksModal.welcome[0].text} ${
            qUser?.legalFirstName ?? ''
          } ${qUser?.legalLastName ?? ''}! ${
            prismicPickYourPerksModal.time_to_enroll[0].text
          }`}
        </StyledText>
        <Gap height="16px" />
        <StyledHeader>
          {prismicPickYourPerksModal.pick_your_perks[0].text}
        </StyledHeader>
      </Container>
      <Modal.Content>
        <Container direction="column" padding="0">
          <Container justify="flex-start">
            <Radio
              style={{ marginRight: 12 }}
              checked={selectedAmbEnrollSku === ambBasicSku}
              onChange={() => onHandleSkuChange(ambBasicSku)}
            />
            <StyledTextSmall
              weight={900}
              align="left"
              onClick={() => onHandleSkuChange(ambBasicSku)}
              style={{ cursor: 'pointer' }}
            >
              {prismicPickYourPerksModal.basic_ambassador[0].text}
            </StyledTextSmall>
          </Container>
          <Gap />

          <Container direction="column" style={{ paddingLeft: 16 }}>
            <Container justify="flex-start">
              <StyledCheck />
              <StyledTextSmall align="left">
                {prismicPickYourPerksModal.full_access_to_arq[0].text}
              </StyledTextSmall>
            </Container>
            <Gap />

            <Container justify="flex-start">
              <StyledCheck />
              <StyledTextSmall align="left">
                {prismicPickYourPerksModal.full_access_to_myqfit[0].text}
              </StyledTextSmall>
            </Container>
          </Container>
        </Container>
        <Gap height="12px" />

        <Container direction="column" padding="0">
          <Container justify="flex-start">
            <Radio
              style={{ marginRight: 12 }}
              checked={selectedAmbEnrollSku === ambPremiumSku}
              onChange={() => onHandleSkuChange(ambPremiumSku)}
            />
            <StyledTextSmall
              weight={900}
              align="left"
              onClick={() => onHandleSkuChange(ambPremiumSku)}
              style={{ cursor: 'pointer' }}
            >
              {prismicPickYourPerksModal.premium_ambassador[0].text}
            </StyledTextSmall>
          </Container>
          <Gap />
          <Container direction="column" style={{ paddingLeft: 16 }}>
            <Container justify="flex-start">
              <StyledCheck />
              <StyledTextSmall align="left">
                {prismicPickYourPerksModal.everything_in_basic[0].text}
              </StyledTextSmall>
            </Container>
            <Gap />

            <Container justify="flex-start">
              <StyledCheck />
              <StyledTextSmall weight={900} align="left">
                {
                  prismicPickYourPerksModal.free_shipping_on_subscriptions[0]
                    .text
                }
              </StyledTextSmall>
            </Container>
          </Container>
          <Gap height="12px" />
          {totalCost ? (
            <AnimatedContainer>
              <StyledText
                weight={900}
              >{`${prismicPickYourPerksModal.total[0].text}: $${totalCost}`}</StyledText>
            </AnimatedContainer>
          ) : (
            <Gap height="32px" />
          )}
          <Gap height="24px" />
          <Divider />
          <Gap height="24px" />
          <Primary onClick={onSubmit} content={`${'continue'.toUpperCase()}`} />
        </Container>
      </Modal.Content>
    </StyledModal>
  )
}

PickYourPerksAmbEnrModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default PickYourPerksAmbEnrModal
