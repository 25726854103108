import React from 'react'
import { SentryWrapper } from './src/components/Sentry'
import StoreProvider from './src/context/StoreContext'
import AuthProvider from './src/context/AuthContext'
import PrismicProvider from './src/context/PrismicContext'
import CartProvider from './src/context/CartContext'

const AppWrapper = ({ element }) => {
  return (
    <SentryWrapper>
      <PrismicProvider>
        <AuthProvider>
          <StoreProvider>
            <CartProvider>{element}</CartProvider>
          </StoreProvider>
        </AuthProvider>
      </PrismicProvider>
    </SentryWrapper>
  )
}

export default AppWrapper
