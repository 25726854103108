import React from 'react'
import { Icon } from 'semantic-ui-react'
import { navigate } from 'gatsby'
// Context
import { usePrismic } from '../../context/PrismicContext'
// Images
import qLogo from '../../images/white-q.png'
// Styles
import {
  CloseButton,
  Container,
  IgniteImage,
  InfoIcon,
  Main,
  QLogo,
  StyledLink,
  StyledSpan,
  Wrapper,
} from './OrderConfirmation.styles'
import useOrderHistory from '../../hooks/useOrderHistory'
import { useCartContext } from '../../context/CartContext'

const OrderConfirmation = ({
  email = '',
  handleRefresh,
  isEventSite,
  orderNumber = '',
  shouldRefresh,
}) => {
  const { refreshOrderData } = useOrderHistory()
  const {
    prismicData: {
      prismicOrderPage: {
        confirmation_number,
        keep_shopping,
        order_history,
        order_number,
        order_received_processing,
        remember_you_can_always,
        see_you_page_orders,
        thanks_your_order,
        to_speed_up_processing,
        will_send_confirmation,
        will_send_email,
      },
      prismicHomepage: { unite_image },
    },
  } = usePrismic()

  const { autoShipItems, autoShipId } = useCartContext()

  const willSendConfirmationText = will_send_confirmation[0].text.replace(
    '{email}',
    email
  )
  const willSendAtIgniteCenter = will_send_email[0].text.replace(
    '{email}',
    email
  )

  const handleOnClick = (link = '/') => {
    if (shouldRefresh) {
      handleRefresh()
    }
    refreshOrderData()
    navigate(link)
  }

  const hasPendingAutoShip = autoShipItems.length > 0 && autoShipId === 0

  const showEventSiteMessage = () => (
    <>
      <IgniteImage src={unite_image.url} />
      <StyledSpan bolder>{thanks_your_order[0].text}</StyledSpan>
      <StyledSpan padded>{order_received_processing[0].text}</StyledSpan>
      <Container padded>
        <Icon.Group>
          <InfoIcon size="huge" name="circle outline" fitted />
          <InfoIcon name="info" size="large" fitted />
        </Icon.Group>
        <Container padded>
          <StyledSpan bolder>{confirmation_number[0].text}:</StyledSpan>
          <StyledLink
            data-qa={`eventOrderNumber-${orderNumber}`}
            onClick={() => handleOnClick('/order-history')}
          >
            {orderNumber}
          </StyledLink>
        </Container>
        <StyledSpan data-qa={`eventEmail-${email}`} bold>
          {willSendAtIgniteCenter}
        </StyledSpan>
      </Container>
      <Container info>
        <StyledSpan emphasized>{to_speed_up_processing[0].text}</StyledSpan>
      </Container>
    </>
  )
  return (
    <Wrapper>
      <Main>
        {isEventSite ? (
          showEventSiteMessage()
        ) : (
          <>
            <QLogo src={qLogo} />
            <StyledSpan bolder>{thanks_your_order[0].text}</StyledSpan>
            <StyledSpan padded>{order_received_processing[0].text}</StyledSpan>
            <Container padded>
              <StyledSpan bolder>{order_number[0].text}:</StyledSpan>
              {shouldRefresh ? (
                <StyledSpan bolder>{`${orderNumber}`}</StyledSpan>
              ) : (
                <StyledLink
                  data-qa={`orderNumber-${orderNumber}`}
                  onClick={() => handleOnClick('/order-history')}
                >
                  {orderNumber}
                </StyledLink>
              )}
            </Container>
            <Container info>
              <StyledSpan data-qa={`email-${email}`} bold>
                {willSendConfirmationText}
              </StyledSpan>
              <StyledSpan emphasized>
                {remember_you_can_always[0].text}{' '}
                {shouldRefresh ? (
                  'Order History'
                ) : (
                  <StyledLink
                    aria-disabled={shouldRefresh}
                    small
                    onClick={() => handleOnClick('/order-history')}
                  >
                    {order_history[0].text}
                  </StyledLink>
                )}{' '}
                {see_you_page_orders[0].text}
              </StyledSpan>
            </Container>
          </>
        )}
        <CloseButton
          data-qa="closeButton"
          style={{ width: '300px' }}
          onClick={() => {
            if (hasPendingAutoShip) {
              handleOnClick('/subscriptions')
            } else {
              handleOnClick('/')
            }
          }}
        >
          {hasPendingAutoShip ? 'SETUP SUBSCRIPTION' : keep_shopping[0].text}
        </CloseButton>
      </Main>
    </Wrapper>
  )
}

export default OrderConfirmation
