import styled, { css } from 'styled-components'
import { Icon, Checkbox } from 'semantic-ui-react'

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  /* justify-content: center; */
  align-items: center;
  max-width: 700px;
  margin: 0 auto;
  min-height: calc(100vh - 240px);
`

interface ContanerProps {
  gray: boolean
  black: boolean
  row: boolean
  justify: string
  align: string
  margin: string
  height: string
  padding: string
  padded: boolean
  header: boolean
  maxWidth: string
}

export const Container = styled.div<Partial<ContanerProps>>`
  background-color: ${props =>
    props.gray
      ? props.theme.colors.silver
      : props.black
      ? props.theme.colors.black
      : props.theme.colors.white};
  color: ${props =>
    props.black ? props.theme.colors.white : props.theme.colors.black};
  display: flex;
  flex-flow: ${props => (props.row ? 'row nowrap' : 'column nowrap')};
  justify-content: ${props => props.justify};
  align-items: ${props => props.align};
  width: 100%;
  max-width: ${props => props.maxWidth};
  padding: ${props =>
    props.padding ? props.padding : props.padded ? '0.5em 1em' : '0px'};
  margin: ${props => props.margin || 0};
  height: ${props => props.height};

  ${props =>
    props.header &&
    css`
      margin: 1em 0;
      background: ${({ theme }) => theme.colors.silver};
      padding: 0.5em 1em;
      align-items: center;
      font-size: ${({ theme }) => theme.fontSize.medium};
      font-weight: bold;
    `}
`

export const HeaderBasic = styled.span`
  font-size: 2em;
  font-weight: bolder;
  margin-top: 1em;
  margin-bottom: 1.5em;
  align-self: center;
`

interface SpanProps {
  bold?: boolean
  isTitle?: boolean
  rowHeader?: boolean
  red?: boolean
  missing?: boolean
  lineThru?: boolean
  padding?: string
}

export const StyledSpan = styled.span<Partial<SpanProps>>`
  padding: ${props => props.padding || '0 0 5px'};
  ${props =>
    props.isTitle &&
    css`
      font-size: 1.3em;
      font-weight: bolder;
    `}

  ${props =>
    props.bold &&
    css`
      font-weight: bolder;
      font-size: 1.1em;
    `}
    
  ${props =>
    props.rowHeader &&
    css`
      font-weight: bolder;
      font-size: 1.2em;
      margin-right: 5px;
    `}

  ${props =>
    props.red &&
    css`
      color: ${({ theme }) => theme.colors.carnelian};
    `}

  ${props =>
    props.missing &&
    css`
      color: ${({ theme }) => theme.colors.linkBlue};
      font-weight: 600;
      padding: 10px 0;
    `}

  ${props =>
    props.lineThru &&
    css`
      text-decoration: line-through;
      padding-right: 0.5em;
      opacity: 0.5;
    `}
`

export const MissingText = styled.span<Partial<SpanProps>>`
  color: ${({ theme }) => theme.colors.linkBlue};
  font-weight: 600;
  padding: 10px 0;
`

export const StyledChevron = styled(Icon)`
  color: ${({ theme }) => theme.colors.linkBlue};
`

export const StyledCheckbox = styled(Checkbox)`
  align-self: start;
  padding-top: 2px;
`
