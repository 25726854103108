import React, { useState } from 'react'
// Styles
import {
  StyledModal,
  StyledHeader,
  MarketLabel,
  Container,
  StyledButton,
} from './StoreLanguage.style'
import { StyledInput, StyledSelect } from '../common'
// Context
import { useStoreContext } from '../../context/StoreContext'
import { usePrismic } from '../../context/PrismicContext'
import { getlanguageCountryMap } from './languageData'

const StoreLanguageModal = ({ open, onClose }) => {
  const {
    userLocale,
    prismicData: {
      prismicMarketAndLanguage,
      prismicExistingQModal: { cancel },
    },
  } = usePrismic()

  const languageCountryMap = getlanguageCountryMap(prismicMarketAndLanguage)

  const { handleChangeStore } = useStoreContext()
  const localeArray = userLocale.split('-')
  const parsedLanguage = localeArray[0]
  const parsedCountry = localeArray[1].toUpperCase()

  const [countryCode, setCountryCode] = useState(parsedCountry)
  const [languageCode, setLanguageCode] = useState(parsedLanguage)

  const storeCode = `${languageCode}_${countryCode}`

  const saveMarket = () => {
    handleChangeStore(storeCode)
    localStorage.removeItem('guestCartId')
    onClose()
  }

  const { title, select_market, select_language, save, markets } =
    prismicMarketAndLanguage

  return (
    <StyledModal
      closeIcon
      open={open}
      centered={false}
      closeOnDimmerClick={false}
      closeOnEscape
      onClose={onClose}
    >
      <StyledHeader>{title[0].text}</StyledHeader>
      <StyledModal.Content>
        <MarketLabel>{select_market[0].text}</MarketLabel>
        <StyledSelect
          value={countryCode}
          onChange={e => setCountryCode(e.target.value)}
        >
          {markets.map(item => (
            <option key={item.store_code} value={item.store_code}>
              {item.label}
            </option>
          ))}
        </StyledSelect>
        <MarketLabel>{select_language[0].text}</MarketLabel>
        {languageCountryMap[countryCode].length > 1 ? (
          <StyledSelect
            value={languageCode}
            onChange={e => setLanguageCode(e.target.value)}
          >
            {languageCountryMap[countryCode].map(item => (
              <option key={item.languageCode} value={item.languageCode}>
                {item.label}
              </option>
            ))}
          </StyledSelect>
        ) : (
          <StyledInput
            disabled
            value={languageCountryMap[countryCode][0].label}
          />
        )}
        <Container>
          <StyledButton onClick={onClose}>
            {cancel[0].text.toUpperCase()}
          </StyledButton>
          <StyledButton onClick={saveMarket}>
            {save[0].text.toUpperCase()}
          </StyledButton>
        </Container>
      </StyledModal.Content>
    </StyledModal>
  )
}

export default StoreLanguageModal
