import { createGlobalStyle } from 'styled-components'
import { device } from './breakpoints'

export const colors = {
  ashGray: '#bab9ad',
  black: '#000000',
  nero: '#181818',
  carnelian: '#ac2a1c',
  dimGray: '#67686b',
  earthYellow: '#d59957',
  ebony: '#65695b',
  glossyGrape: '#b6a0bb',
  laurelGreen: '#b3be91',
  metallicSeaweed: '#007985',
  palestinePurple: '#70346e',
  platinum: '#e4e5e6',
  powderBlue: '#afd2d3',
  raisinBlack: '#231f20',
  sapphireBlue: '#106699',
  linkBlue: '#4183c4',
  silver: '#c2c4c6',
  skyBlue: '#87d1e6',
  spanishGray: '#999b9e',
  tumbleweed: '#eab286',
  turquoiseGreen: '#94d2ba',
  white: '#fff',
  green: '#008000',
  // vipGradient: 'linear-gradient(to right, blue, cyan, blue)',
  vipGradient: '#3CBDED',
  vipBlue: '#3CBDED',
  vipDarkBlue: '#006694',
}

const fontSize = {
  mini: '11px',
  small: '14px',
  default: '16px',
  medium: '18px',
  big: '20px',
  large: '24px',
  xLarge: '28px',
  huge: '32px',
}

export const theme = {
  colors,
  fontSize,
  device,
}

const GlobalStyle = createGlobalStyle`

  *, *::before, *::after {
    box-sizing: border-box;
  }

  html,
  body {
    width: 100%;
  }
  body {
    font-family: "Arial", "Avenir", "Prompt" !important; // important required so google-fonts defaults don't override
  }
  h1,h2,h3,h4 {
    margin: 0px;
  }
  span {
    font-size: 16px;
  }
  @media screen and (max-width: 767px) {
    input, select, textarea {
      font-size: 16px;
    }
  }
`

export default GlobalStyle
