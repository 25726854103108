import React from 'react'
// Styles
import { Container, StyledCheckbox } from './AddToAutoship.styles'

const AddToAutoship = ({ cartOption, setCartOption, nextAutoshipDate }) => {
  return (
    <Container>
      <StyledCheckbox
        checked={cartOption === 'both'}
        fitted
        label={{
          children: (
            <div>
              <div>Add to Cart and Subscription</div>
              <p>
                <span>
                  {`We will add this to your cart now, and to your subscription${
                    nextAutoshipDate ? ` shipping on ${nextAutoshipDate}.` : '.'
                  }`}
                </span>
              </p>
            </div>
          ),
        }}
        name="cartOptionGroup"
        onChange={() => setCartOption('both')}
        radio
        value="both"
      />

      <StyledCheckbox
        checked={cartOption === 'autoship'}
        fitted
        label="Add to Subscription"
        name="cartOptionGroup"
        onChange={() => setCartOption('autoship')}
        radio
        value="autoship"
      />

      <StyledCheckbox
        checked={cartOption === 'cart'}
        fitted
        label="Add to Cart"
        name="cartOptionGroup"
        onChange={() => setCartOption('cart')}
        radio
        value="cart"
      />
    </Container>
  )
}

export default AddToAutoship
