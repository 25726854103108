import React from 'react'
import { navigate } from 'gatsby'
// Context
import { useAuthContext } from '../../context/AuthContext'
// Styles
import {
  StyledModal,
  StyledHeader,
  StyledModalContent,
  QLogo,
  PageHeader,
  PageComment,
  SectionTitle,
  StepItem,
  StepNumber,
  StepText,
  SubmitButton,
} from './NextStepModal.styles'

const NextStepModal = ({ open, setShowModal }) => {
  const {
    manageReferral: { referralData },
  } = useAuthContext()

  const gotoNextStep = async () => {
    navigate('/starter-packs')
  }

  return (
    <StyledModal
      closeIcon={false}
      open={open}
      centered={true}
      closeOnDimmerClick={true}
      closeOnEscape
      onClose={() => {
        setShowModal(false)
        navigate('/starter-packs')
      }}
    >
      <StyledHeader>
        <QLogo src="https://shopq.cdn.prismic.io/shopq/06ea17c4-0c65-4563-9507-bc314540e05f_black-q-sciences.svg" />
        <PageHeader>Ambassador Enrollment</PageHeader>
        {referralData?.ambassadorName && (
          <PageComment>{`(referred by ${referralData?.ambassadorName})`}</PageComment>
        )}
      </StyledHeader>
      <StyledModalContent>
        <SectionTitle>Next Steps</SectionTitle>
        <StepItem>
          <StepNumber>1</StepNumber>
          <StepText>
            Pick your products! To reach active status, add 100 PV worth of
            products to your cart
          </StepText>
        </StepItem>
        <StepItem>
          <StepNumber>2</StepNumber>
          <StepText>
            Proceed to checkout and pay your ambassador enrollment fee
          </StepText>
        </StepItem>
        <SubmitButton onClick={gotoNextStep}>Continue</SubmitButton>
      </StyledModalContent>
    </StyledModal>
  )
}

export default NextStepModal
