import React from 'react'
import { navigate } from 'gatsby'
// Components
import ReorderButton from '../Buttons/ReorderButton'
// Context
import { usePrismic } from '../../context/PrismicContext'
// Images
import qLogo from '../../images/white-q.svg'
// Styles
import {
  Wrapper,
  Main,
  Container,
  StyledSpan,
  QLogo,
  StyledLink,
} from './OrderFailed.styles'
import { CloseButton } from './OrderConfirmation.styles'

type OrderFailedProps = {
  orderNumber: string
  orderStatus: string
  userName: string
  show: React.Dispatch<React.SetStateAction<boolean>>
  errorData: {
    error?: number
    message?: string
    gatewayResponse?: {
      status?: string
    }
  }
}

const OrderFailed = ({
  orderNumber,
  orderStatus,
  userName,
  errorData,
}: OrderFailedProps) => {
  const {
    prismicData: {
      prismicOrderPage: {
        order,
        failed,
        status,
        error_occured_while_processing,
        have_any_questions,
        customer_support,
        rebuild_cart,
        keep_shopping,
      },
    },
  } = usePrismic()

  return (
    <Wrapper>
      <Main>
        <QLogo src={qLogo} />
        <StyledSpan bold>
          {order[0].text}
          <StyledLink to="/order-history">{` ${orderNumber} `}</StyledLink>
          {failed[0].text}
        </StyledSpan>
        <StyledSpan bold>{`${status[0].text}: ${
          errorData?.gatewayResponse?.status ?? orderStatus
        }`}</StyledSpan>
        <Container>
          <StyledSpan>
            {errorData?.message ??
              `${userName}, ${error_occured_while_processing[0].text}`}
          </StyledSpan>
        </Container>
        <Container>
          <StyledSpan emphasized>
            {have_any_questions[0].text}
            <a
              href={`mailto:support@qsciences.com?subject=Re:%20Order%20${orderNumber}`}
            >
              {' '}
              {customer_support[0].text}
            </a>
          </StyledSpan>
        </Container>
        <ReorderButton
          content={rebuild_cart[0].text}
          orderNumber={orderNumber}
        />
        <CloseButton
          data-qa="closeButton"
          style={{ width: '200px' }}
          onClick={() => navigate('/')}
        >
          {keep_shopping[0].text}
        </CloseButton>
      </Main>
    </Wrapper>
  )
}

export default OrderFailed
