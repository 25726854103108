import React from 'react'
import { navigate } from 'gatsby'
// Context
import { useAuthContext } from '../../context/AuthContext'
import { usePrismic } from '../../context/PrismicContext'
// Styles
import { Container, StyledLink, StyledAnchor } from './Footer.styles'

const Disclaimer = () => {
  return (
    <>
      <Container row padding="2em 0 0 0" justify="space-evenly">
        <StyledLink to="/contact-us">Contact</StyledLink>
        <span>|</span>
        <StyledLink to="/privacy-policy">Privacy Policy</StyledLink>
        <span>|</span>
        <StyledLink to="/terms">Terms of Use</StyledLink>
      </Container>
      <Container align="flex-start">
        <p>
          Q Sciences is a cruelty free company. Our products and ingredients are
          not tested on animals.
        </p>
        <p>
          **Products containing CBD or hemp are not for use by or sale to
          persons under the age of 18.
        </p>
        <p>
          The statements made regarding these products have not been evaluated
          by the Food and Drug Administration. These products are not intended
          to diagnose, treat, cure, or prevent any disease.
        </p>
        <p>
          Please consult your health care professional about potential
          interactions or other possible complications before using any product.
        </p>
      </Container>
      <Container align="flex-start" padding="1em 0 0 0">
        <StyledAnchor onClick={() => navigate('/drug-testing')}>
          Drug Testing Statement
        </StyledAnchor>
        <StyledAnchor href="https://shop.myqsciences.com/www/product-tests?type=2">
          Hemp Product Tests/Certificates of Analysis
        </StyledAnchor>
      </Container>
    </>
  )
}

export default Disclaimer
