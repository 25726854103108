import React from 'react'
import { Link } from 'gatsby'
import { Image } from 'semantic-ui-react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Navigation } from 'swiper'
// Context
import { useAuthContext } from '../../context/AuthContext'
import { usePrismic } from '../../context/PrismicContext'
// Images
import body from '../../images/category/whole-body.png'
import comfort from '../../images/category/comfort-relief.png'
import energy from '../../images/category/energy-focus.png'
import fitness from '../../images/category/fitness-weight-loss.png'
import gut from '../../images/category/gut-health-detox.png'
import hair from '../../images/category/hair-skin-nails.png'
import immunity from '../../images/category/immunity.png'
import sleep from '../../images/category/sleep.png'
import stress from '../../images/category/stress.png'
// Styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import './styles.css'

SwiperCore.use([Pagination, Navigation])

const BenefitsSwiper = () => {
  const { prismicData } = usePrismic()
  const { benefit_list } = prismicData.prismicHomepage

  const { isVipSite } = useAuthContext()

  const benefitsArray = [
    {
      url: 'whole-body',
      name: benefit_list[0].text,
      img: body,
    },
    {
      url: 'stress',
      name: benefit_list[1].text,
      img: stress,
    },
    {
      url: 'energy-focus',
      name: benefit_list[2].text,
      img: energy,
    },
    {
      url: 'fitness-weight-loss',
      name: benefit_list[3].text,
      img: fitness,
    },
    {
      url: 'gut-health-detox',
      name: benefit_list[4].text,
      img: gut,
    },
    {
      url: 'sleep',
      name: benefit_list[5].text,
      img: sleep,
    },
    {
      url: 'immunity',
      name: benefit_list[6].text,
      img: immunity,
    },
    {
      url: 'comfort-relief',
      name: benefit_list[7].text,
      img: comfort,
    },
    {
      url: 'hair-skin-nails',
      name: benefit_list[8].text,
      img: hair,
    },
  ]

  const renderBenefits = benefits =>
    benefits.map(benefit => (
      <SwiperSlide data-qa={`benefit-${benefit.name}`} key={`${benefit.url}`}>
        <Image
          as={Link}
          to={`products/benefit/${benefit.url}`}
          src={benefit.img}
          size="tiny"
        />
        <p>{benefit.name}</p>
      </SwiperSlide>
    ))

  const swiperClassName = isVipSite ? 'benefit-swiper-vip' : 'benefit-swiper'

  return (
    <>
      <Swiper
        breakpoints={{
          480: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 6,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 8,
            spaceBetween: 20,
          },
        }}
        loop={true}
        loopFillGroupWithBlank={true}
        pagination={{
          clickable: true,
        }}
        slidesPerView={4}
        spaceBetween={20}
        className={swiperClassName}
        data-qa="benefitSwiper"
      >
        {renderBenefits(benefitsArray)}
      </Swiper>
    </>
  )
}

export default BenefitsSwiper
