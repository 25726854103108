import React, { useEffect } from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
// Images
import qLogo from '../../images/white-q.png'
// Styles
import { LoadingWrapper, Logo, dimmerStyle } from './Loading.styles'
import environment from '../../utils/environment'

const Loading = ({ loading, message, showLogo = true }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const isEventOrVip = environment.IS_EVENT_SITE || environment.IS_VIP_SITE

  return (
    <LoadingWrapper data-qa="loading" isEvent={isEventOrVip}>
      <Dimmer style={dimmerStyle} active={loading}>
        {showLogo ? <Logo src={qLogo} /> : null}
        <Loader style={{ marginTop: '100px' }} size="huge">
          {message}
        </Loader>
      </Dimmer>
    </LoadingWrapper>
  )
}

export default Loading
