import React, { useState } from 'react'
import { ErrorMessage } from '../components'

function useShowError() {
  const [error, setError] = useState('')

  const clearError = () => setError('')

  const showError = () => (
    <ErrorMessage header="Error" message={error} cb={clearError} />
  )

  return [error, setError, showError]
}

export default useShowError
