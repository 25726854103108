import React from 'react'
import Calendar from 'react-calendar'
import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'
import { getDate, addDays } from 'date-fns'
import toast from 'react-hot-toast'
// Contexts
import { useAuthContext } from '../../context/AuthContext'
import { useCartContext } from '../../context/CartContext'
import { usePrismic } from '../../context/PrismicContext'
// Styles
import { Container } from '../../styles/common'

import 'react-calendar/dist/Calendar.css'

const StyledCalendar = styled(Calendar)`
  && {
    border: none;
    button:disabled {
      color: #acacac;
      background: white;
    }
    .dayView {
      color: black;
      height: 50px;
      width: 50px;

      :disabled {
        color: #acacac;
        background: white;
        :hover {
          background: white;
        }
      }

      :hover {
        /* metallicSeaweed with opacity */
        background-color: rgba(0, 121, 133, 0.4);
        border-radius: 50%;
      }
    }
    .react-calendar__navigation__label {
      color: black !important;
    }
  }
`

type SetupProps = {
  children?: React.ReactElement
}

const Setup = ({ children }: SetupProps) => {
  const {
    prismicData: {
      prismicAutoshipPage: {
        autoship_processing_date,
        autoship_setup_description,
      },
    },
  } = usePrismic()

  const { userType } = useAuthContext()

  const {
    autoShipDate,
    autoShipId,
    manageAutoShip,
    handleSetAutoShipState,
    updateAutoShipState,
    isAmbOfferInCart,
  } = useCartContext()

  const handleChange = async (value: Date) => {
    const localDate = new Date(value).toLocaleDateString()
    if (autoShipId) {
      const data = {
        autoShipId,
        dateNextProcess: localDate,
        isActive: true,
      }
      await manageAutoShip
        .update(data, updateAutoShipState)
        .then(() => toast.success('Subscription process date updated!'))
    } else {
      handleSetAutoShipState({
        autoShipDate: localDate,
      })
    }
  }

  const isInRange = ({ date }) => {
    const day = getDate(date)

    if (userType === 'AMBASSADOR' || isAmbOfferInCart) {
      return day >= 16
    }
    return day === 0
  }

  const datePlusTwo = addDays(new Date(), 2)

  return (
    <>
      <Container padded>
        <span>{autoship_setup_description[0].text}</span>
      </Container>
      <Container header>{autoship_processing_date[0].text}</Container>
      <StyledCalendar
        minDate={datePlusTwo}
        minDetail="month"
        next2Label={null}
        nextLabel={<Icon name="chevron right" />}
        onChange={handleChange}
        prev2Label={null}
        prevLabel={<Icon name="chevron left" />}
        tileClassName="dayView"
        tileDisabled={isInRange}
        value={autoShipDate}
      />
      {children}
    </>
  )
}

Setup.displayName = 'AutoshipSetup'

export default Setup
