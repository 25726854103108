import React from 'react'
import { Icon } from 'semantic-ui-react'
// Components
import Disclaimer from './Disclaimer'
// Context
import { useAuthContext } from '../../context/AuthContext'
import { usePrismic } from '../../context/PrismicContext'
// Styles
import { Wrapper, Container, Logo, StyledSpan } from './Footer.styles'

const Footer = () => {
  const {
    prismicData: {
      prismicFooter: {
        logo,
        facebook,
        instagram,
        linkedin,
        twitter,
        copyright,
      },
    },
  } = usePrismic()

  const { isVipSite } = useAuthContext()

  return (
    <Wrapper isVip={isVipSite}>
      <Container>
        <Container>
          <Logo src={logo.url} />
        </Container>
        <Container row>
          <a
            data-qa="footerFacebook"
            href={facebook.url}
            rel="noreferrer"
            target={facebook.target}
          >
            <Icon name="facebook f" size="big" />
          </a>
          <a
            data-qa="footerInstagram"
            href={instagram.url}
            rel="noreferrer"
            target={instagram.target}
          >
            <Icon name="instagram" size="big" />
          </a>
          <a
            data-qa="footerTwitter"
            href={twitter.url}
            rel="noreferrer"
            target={twitter.target}
          >
            <Icon name="twitter" size="big" />
          </a>
          <a
            data-qa="footerLinkedin"
            href={linkedin.url}
            rel="noreferrer"
            target={linkedin.target}
          >
            <Icon name="linkedin" size="big" />
          </a>
        </Container>
        <StyledSpan>{copyright[0].text}</StyledSpan>
      </Container>
      <Disclaimer />
    </Wrapper>
  )
}

export default Footer
