import styled from 'styled-components'
import { Modal, Header } from 'semantic-ui-react'
import { Primary as PrimaryButton } from '..'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px;

  .ui.basic.label {
    align-self: center;
    white-space: nowrap;
    border: none;
  }
`

const DetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
  font-weight: bold;
  margin: 3px 0;
  width: 100%;
`

const StyledModal = styled(Modal)`
  width: 90% !important;

  @media ${({ theme }) => theme.device.sm} {
    width: 600px !important;
  }

  &&& {
    i.close {
      top: 0 !important;
      left: 0;
      color: ${({ theme }) => theme.colors.black};
    }
  }
`

const StyledModalContent = styled(Modal.Content)`
  padding: 10px !important;
`

const StyledHeader = styled(Header)`
  text-align: center;
  font-size: 18px !important;
  font-weight: bold !important;
  border-bottom: 0 !important;

  @media ${({ theme }) => theme.device.sm} {
    font-size: 22px !important;
  }
`

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 0 10px;

  @media ${({ theme }) => theme.device.sm} {
    padding: 0 30px;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 20px;
`

const StyledButton = styled(PrimaryButton)`
  &&& {
    text-transform: uppercase;
    margin: 0 0 1rem;
  }
`

const StyledSpan = styled.span`
  padding: 0 0 5px;
  text-align: center;
  @media ${({ theme }) => theme.device.sm} {
    text-align: left;
  }
`

export const AS = {
  Wrapper,
  DetailsRow,
  StyledModal,
  StyledHeader,
  StyledModalContent,
  Container,
  StyledButton,
  ButtonsWrapper,
  StyledSpan,
}
