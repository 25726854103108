import React, { useEffect, useState } from 'react'
// Context
import { useAuthContext } from '../../context/AuthContext'
import { useStoreContext } from '../../context/StoreContext'
// Styles
import {
  ProductCostInfo,
  ProductCostInfoWrapper,
  ProductCount,
  ProductImage,
  ProductItem,
  ProductTitle,
  StyledSpan,
} from './Orders.styles'
import { enrollSkus } from '../../utils/constants'

const tempImageUrl =
  'https://react.semantic-ui.com/images/wireframe/square-image.png'

const pcEnrollmentImage =
  'https://mcstaging.qsciences.com/media/catalog/product/cache/f7ddf621f71ddefefa75bcb5dd154ba9/b/l/black-q_1.png'

const ambEnrollmentImage =
  'https://mcstaging.qsciences.com/media/catalog/product/cache/f7ddf621f71ddefefa75bcb5dd154ba9/2/0/2022_shopq_enrollmenticons_ambassenrollment.png'

const arqImage =
  'https://mcstaging.qsciences.com/media/catalog/product/cache/f7ddf621f71ddefefa75bcb5dd154ba9/2/0/2022_shopq_enrollmenticons_arq.png'

const myQFitImage =
  'https://mcstaging.qsciences.com/media/catalog/product/cache/f7ddf621f71ddefefa75bcb5dd154ba9/2/0/2022_shopq_enrollmenticons_mqf.png'

const OrderItem = ({
  orderNumber,
  product_name,
  product_sale_price,
  product_sku,
  product_url_key,
  quantity_ordered,
  returnPv,
  selected_options,
}) => {
  const [product, setProduct] = useState(null)
  const { userType } = useAuthContext()
  const { storeData, isStoreLoading } = useStoreContext()

  useEffect(() => {
    if (isStoreLoading) return
    setProduct(getProductData())
  }, [isStoreLoading])

  const getProductData = () => {
    const productData = storeData['all-products']?.products?.find(
      product => product.url_key === product_url_key
    )
    returnPv(prev => prev + Number(productData?.pv ?? 0))
    return productData
  }

  const regularPrice =
    product?.price_range?.maximum_price?.regular_price?.value?.toFixed(2) ?? ''
  const finalPrice = product_sale_price?.value?.toFixed(2) ?? ''

  const showRegularPrice = regularPrice > finalPrice

  const productName = selected_options.length
    ? `${product_name} - ${selected_options[0].value}`
    : product_name

  const getOfferImage = () => {
    if (product_sku === enrollSkus.pcFee) {
      return pcEnrollmentImage
    } else if (
      product_sku === enrollSkus.ambPremiumSku ||
      product_sku === enrollSkus.ambBasicSku
    ) {
      return ambEnrollmentImage
    } else if (product_sku === enrollSkus.arq) {
      return arqImage
    } else if (product_sku === enrollSkus.myQFit) {
      return myQFitImage
    } else {
      return tempImageUrl
    }
  }

  const productImage = product?.image?.url || getOfferImage()

  return (
    <ProductItem>
      <ProductImage
        data-qa={`order-${orderNumber}-image-${product_sku}`}
        src={productImage}
      />
      <ProductTitle data-qa={`order-${orderNumber}-title-${product_sku}`} bold>
        {productName}
      </ProductTitle>
      <ProductCount data-qa={`order-${orderNumber}-quantity-${product_sku}`}>
        {quantity_ordered}
      </ProductCount>
      <ProductCostInfoWrapper>
        <ProductCostInfo>
          {showRegularPrice ? (
            <StyledSpan
              data-qa={`order-${orderNumber}-regularPrice-${product_sku}`}
              lineThru
            >
              ${regularPrice}
            </StyledSpan>
          ) : null}

          <StyledSpan
            data-qa={`order-${orderNumber}-finalPrice-${product_sku}`}
            width="100%"
            textAlign="right"
          >
            ${finalPrice}
          </StyledSpan>
        </ProductCostInfo>
        {userType === 'AMBASSADOR' && product?.pv ? (
          <ProductCostInfo>
            <StyledSpan bold>PV</StyledSpan>

            <StyledSpan data-qa={`order-${orderNumber}-pv-${product_sku}`}>
              {product?.pv}
            </StyledSpan>
          </ProductCostInfo>
        ) : null}
      </ProductCostInfoWrapper>
    </ProductItem>
  )
}

export default React.memo(OrderItem)
