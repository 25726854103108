import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { Image } from 'semantic-ui-react'

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.black};

  ${props =>
    props.isVip &&
    css`
      background: ${({ theme }) => theme.colors.vipGradient};
    `}
`

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: ${props => props.justify || 'center'};
  align-items: ${props => props.align || 'center'};
  margin: ${props => props.margin || '0'};
  padding: ${props => props.padding || '0'};
  width: 100%;
  max-width: 600px;
  ${props =>
    props.row &&
    css`
      flex-flow: row nowrap;
      margin-bottom: 2em;
      a:link,
      a:visited {
        color: ${({ theme }) => theme.colors.white};
        margin: 0 5px;
      }
    `}

  p {
    font-style: italic;
    font-size: 0.85em;
    opacity: 0.5;
  }
`

export const StyledAnchor = styled.a`
  color: ${props => props.theme.colors.white};
  font-style: italic;
  opacity: 0.8;
  font-size: 0.85em;
  margin-bottom: 5px;
  cursor: pointer;
`

export const StyledLink = styled(Link)`
  &&& {
    color: ${({ theme }) => theme.colors.white};
    font-weight: bold;
    font-size: ${props => props.size || '1.1rem'};
  }
`

export const StyledSpan = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-style: italic;
`

export const Logo = styled(Image)`
  &&& {
    width: auto;
    height: 80px;
  }
`
