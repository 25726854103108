import React from 'react'
import {
  StyledButton, StyledModalContent,
  StyledText, Container,
  StyledHeader, StyledParagraph,
  StyledButtonText, StyledModal
} from "./Nexio3dsModal.styles";

import {usePrismic} from "../../context/PrismicContext";


export const Nexio3dsModal = ({open, onClose, onYes}) => {

  const {
    prismicData: {
      prismicNexioRedirect: {
        cancel,
        continue_text,
        modaltext,
        modalheader
      }
    }
  } = usePrismic()

  return (
    <StyledModal
      closeIcon
      open={open}
      centered={true}
      closeOnDimmerClick={true}
      closeOnEscape
      onClose={onClose}>
      <StyledModalContent>
        <StyledHeader>
          <StyledText>{modalheader[0].text}</StyledText>
        </StyledHeader>
        <StyledParagraph>
          {modaltext[0].text}
          <br/>
          <br/>
          {modaltext[1].text}
        </StyledParagraph>
        <Container justify="flex-end">
          <StyledButton onClick={onClose}>
            <StyledButtonText>
              <strong>{cancel[0].text}</strong>
            </StyledButtonText>
          </StyledButton>
          <StyledButton onClick={onYes}>
            <StyledButtonText>
              <strong>{continue_text[0].text}</strong>
            </StyledButtonText>
          </StyledButton>
        </Container>
      </StyledModalContent>
    </StyledModal>
  )
}