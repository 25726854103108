import React from 'react'
import { Button, Input, Label } from 'semantic-ui-react'
// Components
import { CartProductCard, SearchMenu } from '../'
// Context
import { useAuthContext } from '../../context/AuthContext'
import { useCartContext } from '../../context/CartContext'
import { usePrismic } from '../../context/PrismicContext'
// Styles
import { Container } from '../../styles/common'
import { AS } from './styled'

type ManageProps = {
  children?: React.ReactElement
}

const Details = ({
  pvTotalText = '',
  reward = 0,
  rewardPointsEarnedText = '',
  showPv,
  hideShippingCost,
  total = 0,
  totalPv = 0,
  totalText = '',
  prismicCartPage,
}) => {
  const shippingPrice =
    total < 150
      ? prismicCartPage.first_tier_shipping[0].text
      : prismicCartPage.second_tier_shipping[0].text
  const shippingCost = total > 0 ? shippingPrice : '$0.00'
  const handlingCost =
    total > prismicCartPage.handling_price ? prismicCartPage.handling_price : 0
  return (
    <>
      <AS.DetailsRow>
        <span>{totalText}</span>
        <span>${total.toFixed(2)}</span>
      </AS.DetailsRow>
      {showPv ? (
        <AS.DetailsRow>
          <span>{pvTotalText}</span>
          <span>{totalPv}</span>
        </AS.DetailsRow>
      ) : null}
      <AS.DetailsRow>
        <span>{prismicCartPage.shipping[0].text}</span>
        <span>{hideShippingCost ? 'Included' : shippingCost}</span>
      </AS.DetailsRow>
      {prismicCartPage.handling_price > 0 && (
        <AS.DetailsRow>
          <span>{prismicCartPage.handling[0].text}</span>
          <span>{`$${handlingCost.toFixed(2)}`}</span>
        </AS.DetailsRow>
      )}
      {/* <AS.DetailsRow>
      <span>{rewardPointsEarnedText}</span>
      <span>{reward || 'TBD'}</span>
    </AS.DetailsRow> */}
    </>
  )
}

const Manage = ({ children }: ManageProps) => {
  const {
    prismicData: {
      prismicAutoshipPage: {
        next_autoship,
        pv_total: pvTotalText,
        reward_points_earned,
        total: totalText,
      },
      prismicCartPage,
    },
  } = usePrismic()
  const {
    autoShipItems,
    autoShipDate,
    handleSetAutoShipState,
    addToAutoShip,
    isAmbOfferInCart,
    isAmbPremiumOfferInCart,
    getTotalOfAutoShipItems,
  } = useCartContext()
  const { userType, qUser } = useAuthContext()

  const handleClearDate = () => handleSetAutoShipState({ autoShipDate: '' })

  return (
    <>
      <AS.Wrapper>
        <Input value={autoShipDate} onChange={handleClearDate} action>
          <Label basic>{next_autoship[0].text}</Label>
          <input />
          <Button basic icon="calendar alternate" onClick={handleClearDate} />
        </Input>
        <SearchMenu type="add" callback={addToAutoShip} />
      </AS.Wrapper>
      <Container padded>
        {autoShipItems?.map((item, i) => {
          return (
            <div key={`${item?.autoShipItemId ?? item.sku}-${i}`}>
              <CartProductCard item={item} />
            </div>
          )
        })}
        <Container padding="20px 0 0 0">
          <Details
            {...getTotalOfAutoShipItems()}
            totalText={totalText[0].text}
            pvTotalText={pvTotalText[0].text}
            rewardPointsEarnedText={reward_points_earned[0].text}
            showPv={userType === 'AMBASSADOR' || isAmbOfferInCart}
            hideShippingCost={
              isAmbPremiumOfferInCart ||
              qUser?.hasAmbassadorAutoshipFreeShipping ||
              qUser?.hasPreferredCustomerAutoShipFreeShipping
            }
            prismicCartPage={prismicCartPage}
          />
        </Container>
      </Container>
      {children}
    </>
  )
}

Manage.displayName = 'AutoshipManage'

export default Manage
