import React, { useEffect, useState } from 'react'
import { Menu, Segment, Sidebar } from 'semantic-ui-react'
import { navigate } from 'gatsby'
// Context
import { useCartContext } from '../../context/CartContext'
import { usePrismic } from '../../context/PrismicContext'
// Styles
import { Container } from '../../styles/common'
import {
  CartCount,
  CartItem,
  ProductImage,
  StyledText,
  FilledButton,
  OutlinedButton,
} from './AddToCardPopup.styles'
// Utils
import { enrollSkus } from '../../utils/constants'
import './sidebar.css'
import { useAuthContext } from '../../context/AuthContext'

const CardPricingDetails = ({
  isLoading,
  cartItem,
  handleRemoveFromCart,
  upgradeInCart,
  prismicText,
  showPv,
}) => {
  const { product, quantity, prices, uid } = cartItem
  const { name, price_range, sku, wholesale_price, pv } = product

  const configOption = cartItem?.configurable_options?.[0] ?? null

  const buildName = !!configOption
    ? `${name} - ${configOption.value_label}`
    : `${name}`

  const regularPrice =
    price_range?.maximum_price?.regular_price?.value * quantity
  const finalPrice = prices?.price?.value * quantity
  const wholesalePrice = wholesale_price && Number(wholesale_price) * quantity
  const hasDiscount = upgradeInCart
    ? wholesalePrice < regularPrice
    : finalPrice < regularPrice
  const pvTotal = pv && Number(pv) * quantity

  const isEnrollmentProduct = Object.values(enrollSkus).includes(sku)
  const pricing = {
    regularPrice: regularPrice.toFixed(2),
    finalPrice: upgradeInCart
      ? wholesalePrice.toFixed(2)
      : finalPrice.toFixed(2),
  }

  return (
    <Container margin="0 0 0 1em">
      <Container>
        <StyledText bold>{buildName}</StyledText>
      </Container>
      <Container row>
        {isEnrollmentProduct || !hasDiscount ? (
          <StyledText
            data-qa={`priceBold-${sku}`}
          >{`$${pricing.regularPrice}`}</StyledText>
        ) : (
          <>
            <StyledText
              data-qa={`priceLine-${sku}`}
              lineThru
            >{`$${pricing.regularPrice}`}</StyledText>
            <StyledText
              data-qa={`priceBold-${sku}`}
              bold
            >{`$${pricing.finalPrice}`}</StyledText>
          </>
        )}
      </Container>

      {showPv && pvTotal ? (
        <Container row>
          <StyledText padding="0 0.5em 0 0">PV:</StyledText>
          <StyledText>{pvTotal}</StyledText>
        </Container>
      ) : null}
      <Container row>
        <StyledText padding="0 0.5em 0 0">
          {prismicText.qty[0].text}:
        </StyledText>
        <StyledText>{quantity}</StyledText>
      </Container>

      <Container row padding="0.5em 1em 0 0">
        <OutlinedButton
          loading={isLoading}
          disabled={isLoading}
          onClick={() => handleRemoveFromCart(uid, sku)}
        >
          {prismicText.remove[0].text}
        </OutlinedButton>
      </Container>
    </Container>
  )
}

const AddToCartPopup = () => {
  const { userType } = useAuthContext()
  const {
    buildCart,
    cartData,
    cartId,
    isPcOfferInCart,
    isAmbOfferInCart,
    isShowCartPopup,
    manageCart,
    showCartPopup,
    downgradeUserRemovePcFee,
  } = useCartContext()

  const {
    prismicData: {
      prismicCartPage: {
        your_cart,
        sub_total,
        check_out_now,
        keep_shopping,
        qty,
        remove,
      },
    },
  } = usePrismic()

  const [isLoading, setIsLoading] = useState(false)

  const cartCount = cartData?.items?.reduce(
    (agg, { quantity }) => agg + (quantity || 0),
    0
  )

  const totalPv = cartData?.items?.reduce(
    (agg, { product, quantity }) => agg + (Number(product.pv) || 0) * quantity,
    0
  )

  useEffect(() => {
    if (!cartCount) showCartPopup(false)
  }, [cartCount])

  const handleRemoveFromCart = async (uid, sku) => {
    setIsLoading(true)
    if (sku === enrollSkus.pcFee) await downgradeUserRemovePcFee(uid)
    else await manageCart.removeItemFromCart(uid, cartId, buildCart)
    setIsLoading(false)
  }

  const subTotal =
    isPcOfferInCart || isAmbOfferInCart
      ? cartData?.wholesale_total?.toFixed(2)
      : cartData?.prices?.subtotal_excluding_tax?.value?.toFixed(2) || 0

  const showPv = (isAmbOfferInCart || userType === 'AMBASSADOR') && totalPv > 0

  return (
    <Sidebar.Pushable as={Segment} className="cart-sidebar">
      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        direction="right"
        inverted
        vertical
        visible={isShowCartPopup}
        width="thin"
        onHide={() => showCartPopup(false)}
      >
        <Container>
          <CartCount isTitle>
            {your_cart[0].text} ({cartCount})
          </CartCount>
        </Container>
        <Container padding="0 1em">
          {cartData &&
            cartData?.items?.map((cartItem: any, index: number) => (
              <CartItem row align="flex-start" key={index}>
                <ProductImage src={cartItem.product?.image?.url} size="small" />
                <CardPricingDetails
                  isLoading={isLoading}
                  cartItem={cartItem}
                  handleRemoveFromCart={handleRemoveFromCart}
                  upgradeInCart={isPcOfferInCart || isAmbOfferInCart}
                  prismicText={{ qty, remove }}
                  showPv={showPv}
                />
              </CartItem>
            ))}
        </Container>
        <Container row padding={showPv ? '1em 0 0 1em' : '1em'}>
          <StyledText bold>{sub_total[0].text}:</StyledText>
          <StyledText bold padding="0 0 0 1em">
            {`$${subTotal}`}
          </StyledText>
        </Container>
        {showPv && (
          <Container row padding="0 0 1em 1em">
            <StyledText bold>Total PV:</StyledText>
            <StyledText bold padding="0 0 0 1em">
              {`${totalPv}`}
            </StyledText>
          </Container>
        )}

        <Container align="center">
          <FilledButton
            onClick={() => {
              showCartPopup(false)
              navigate('/cart')
            }}
          >
            {check_out_now[0].text}
          </FilledButton>

          <FilledButton onClick={() => showCartPopup(false)}>
            {keep_shopping[0].text}
          </FilledButton>
        </Container>
      </Sidebar>

      <Sidebar.Pusher>
        <></>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  )
}

export default AddToCartPopup
