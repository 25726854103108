import React from 'react'
import { navigate } from 'gatsby'
// Components
import { Primary as CloseButton } from '../'
// Context
import { usePrismic } from '../../context/PrismicContext'
// Styles
import {
  Container,
  StyledImage,
  StyledModal,
  StyledSpan,
} from '../ProductCard/AddToModal.styles'
// Utils
import environment from '../../utils/environment'

const EventLoginModal = ({ open, handleCloseModal }) => {
  const {
    prismicData: {
      prismicHomepage: {
        unite_image: { url },
      },
    },
  } = usePrismic()

  const handleClose = () => {
    handleCloseModal(false)
    navigate('https://qsciences.com')
  }

  return (
    <StyledModal
      centered={true}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      onClose={handleClose}
      open={open}
    >
      <StyledImage style src={url} fluid />
      <Container column align="center" padding="1em">
        <StyledSpan isTitle>We're sorry...</StyledSpan>
        <span style={{ textAlign: 'center' }}>
          {`This site is limited to UNITE ${
            environment.IS_VIP_SITE ? 'VIP' : ''
          } attendees. If you want to make a
          purchase, please visit qsciences.com`}
        </span>
        <CloseButton onClick={handleClose} content="Visit Shop Q" />
      </Container>
    </StyledModal>
  )
}

export default EventLoginModal
