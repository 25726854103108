import * as yup from 'yup'
import { formatTaxDataFromPrismic } from './i18n/enrollmentForm'

// ------------------------ User Authorization ------------------------

export const signUpSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .required('Password is required')
    .matches(/[a-z]/, 'Must contain at least 1 lowercase letter')
    .matches(/[A-Z]/, 'Must contain at lease 1 uppercase letter')
    .matches(/\d/, 'Must contain at least 1 digit')
    .min(8, 'Password must be at least 8 characters'),
  passwordConfirm: yup
    .string()
    .required('Confirm password is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
})

export const signInSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('Email is required'),
  password: yup.string().required('Password is required'),
})

export const forgotPasswordSchema = yup.object().shape({
  resetEmail: yup
    .string()
    .email('Please enter a valid email')
    .required('Email is required'),
})

// ------------------------ Q Services Account Validation ------------------------

export const existingCustomer = yup.object().shape({
  customer: yup.string().required('Customer ID or Username is required'),
})

export const newCustomer = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
})

export const enterCode = yup.object().shape({
  code: yup
    .string()
    .required('Code is required')
    .matches(/^[0-9]{6}/, 'Invalid code, please try again'),
})

export const validationSchema = yup.object().shape({
  password: yup
    .string()
    .test('oneOfRequired', 'You must enter one field', function (item) {
      return this.parent.password || this.parent.email || this.parent.phone
    }),
  email: yup
    .string()
    .test('oneOfRequired', 'You must enter one field', function (item) {
      return this.parent.password || this.parent.email || this.parent.phone
    }),
  phone: yup
    .string()
    .test('oneOfRequired', 'You must enter one field', function (item) {
      return this.parent.password || this.parent.email || this.parent.phone
    }),
})

// ------------------------ Checkout ------------------------
export const buildShippingAddressSchema = prismic => {
  const shippingAddressSchema = yup.object().shape({
    firstname: yup
      .string()
      .required(prismic.first_name_required[0].text)
      .max(25, prismic.must_not_exceed_25[0].text),
    lastname: yup
      .string()
      .required(prismic.last_name_required[0].text)
      .max(25, prismic.must_not_exceed_25[0].text),
    address1: yup
      .string()
      .required(prismic.address1_required[0].text)
      .max(prismic.address1_max_length, prismic.must_not_exceed_30[0].text),
    address2: yup
      .string()
      .max(prismic.address1_max_length, prismic.must_not_exceed_30[0].text),
    city: yup
      .string()
      .required(prismic.city_required[0].text)
      .max(prismic.address1_max_length, prismic.must_not_exceed_30[0].text),
    state: yup.string().required(prismic.state_required[0].text),
    postcode: yup
      .string()
      .required(prismic.zip_required[0].text)
      .matches(new RegExp(prismic.zip_regex[0].text), prismic.digit_zip[0].text)
      .length(prismic.zip_length, prismic.digit_zip[0].text),
    country_code: yup.string().required(prismic.country_required[0].text),
    telephone: yup
      .string()
      .required(prismic.phone_required[0].text)
      .min(prismic.phone_min, prismic.digits_required[0].text)
      .max(prismic.phone_max, prismic.digits_required[0].text)
      .matches(
        new RegExp(prismic.phone_regex[0].text),
        prismic.digits_required[0].text
      ),
  })
  return shippingAddressSchema
}

export const addCouponCode = yup.object().shape({
  code: yup.string().max(20, 'Too long'),
})

export const buildEnrollmentSchema = prismic => {
  // dynamically build schema for personal tax fields
  const ssnFromPrismic = formatTaxDataFromPrismic(prismic.ssn_list)
  const ssnSchema = ssnFromPrismic.map(({ name, message, regex }) => ({
    [name]: yup.string().test('oneOfRequired', message, function () {
      if (this.parent.identification !== 'personal') return true
      const re = new RegExp(regex)
      if (!re.test(this.parent[name])) return false
      return this.parent[name]
    }),
  }))
  // dynamically build schema for business tax fields
  const einFromPrismic = formatTaxDataFromPrismic(prismic.ein_list)
  const einSchema = einFromPrismic.map(({ name, message, regex }) => ({
    [name]: yup.string().test('oneOfRequired', message, function () {
      if (this.parent.identification === 'personal') return true
      const re = new RegExp(regex)
      if (!re.test(this.parent[name])) return false
      return this.parent[name]
    }),
  }))
  const enrollmentSchema = yup.object().shape({
    identification: yup
      .string()
      .required(prismic.identification_required[0].text),
    ...Object.assign({}, ...ssnSchema),
    ...Object.assign({}, ...einSchema),
    companyName: yup
      .string()
      .test('oneOfRequired', prismic.company_required[0].text, function () {
        if (this.parent.identification !== 'business') {
          return true
        }
        return this.parent.companyName
      }),
    legalFirstName: yup.string().required(prismic.first_name_required[0].text),
    legalLastName: yup.string().required(prismic.last_name_required[0].text),
    primaryPhoneNumber: yup
      .string()
      .required(prismic.phone_required[0].text)
      .min(prismic.phone_min, prismic.digits_required[0].text)
      .max(prismic.phone_max, prismic.digits_required[0].text)
      .matches(
        new RegExp(prismic.phone_regex[0].text),
        prismic.digits_required[0].text
      ),
    emailAddress: yup
      .string()
      .email(prismic.enter_valid_email[0].text)
      .required(prismic.email_required[0].text),
    dateBirth: yup
      .string()
      .required(prismic.birthday_required[0].text)
      .matches(
        new RegExp(prismic.birthday_regex[0].text),
        prismic.birthday_format[0].text
      ),
    address1: yup
      .string()
      .required(prismic.address1_required[0].text)
      .max(prismic.address1_max_length, prismic.must_not_exceed_30[0].text),
    address2: yup
      .string()
      .max(prismic.address1_max_length, prismic.must_not_exceed_30[0].text),
    city: yup
      .string()
      .required(prismic.city_required[0].text)
      .max(prismic.address1_max_length, prismic.must_not_exceed_30[0].text),
    state: yup.string().required(prismic.state_required[0].text),
    zip: yup
      .string()
      .required(prismic.zip_required[0].text)
      .matches(new RegExp(prismic.zip_regex[0].text), prismic.digit_zip[0].text)
      .length(prismic.zip_length, prismic.digit_zip[0].text),
    country: yup.string().required(prismic.country_required[0].text),
    slug: yup
      .string()
      .required(prismic.field_required[0].text)
      .matches(
        new RegExp(prismic.slug_regex[0].text),
        prismic.letters_and_numbers[0].text
      )
      .min(3, prismic.slug_min_char[0].text)
      .max(60, prismic.slug_max_char[0].text),
    send1099FormElectronically: yup
      .boolean()
      .oneOf([true], prismic.required_1099[0].text),
    areTermsAccepted: yup
      .boolean()
      .oneOf([true], prismic.tncs_required[0].text),
  })
  return enrollmentSchema
}
