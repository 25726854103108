import React, { useEffect, useState } from 'react'
// Context
import { useAuthContext } from '../context/AuthContext'
import { useCartContext } from '../context/CartContext'
// Styles
import {
  Container,
  StyledSpan,
} from '../components/Cart/CartProductCard.styles'
// Types
import { ProductType } from '../types/ProductTypes'
import { oneYearFromToday } from '../utils/dateHelpers'
import { enrollSkus } from '../utils/constants'

const useShowProductPrice = (productData: ProductType) => {
  const [product, setProduct] = useState({ ...productData })

  const { userType } = useAuthContext()

  const { isPcOfferInCart, isAmbOfferInCart } = useCartContext()

  useEffect(() => {
    setProduct(productData)
  }, [productData])

  const isOfferProduct = Object.values(enrollSkus).includes(product?.sku)

  const finalPrice = product?.price_range?.maximum_price?.final_price?.value
  const regularPrice = product?.price_range?.maximum_price?.regular_price?.value
  const wholesalePrice =
    product?.wholesale_price && Number(product?.wholesale_price)
  const hasDiscount = finalPrice < regularPrice
  const showPv =
    (isAmbOfferInCart || userType === 'AMBASSADOR') && product?.pv !== '0'
  const showDiscount =
    ((isPcOfferInCart || isAmbOfferInCart) && regularPrice > wholesalePrice) ||
    (userType !== 'RETAIL' && hasDiscount)

  const ProductPricing = ({ isCart = false, quantity = 1 }) => {
    const pricing = {
      regularPrice: (regularPrice * quantity).toFixed(2),
      finalPrice:
        isPcOfferInCart || isAmbOfferInCart
          ? (wholesalePrice * quantity).toFixed(2)
          : (finalPrice * quantity).toFixed(2),
    }

    const savings =
      isPcOfferInCart || isAmbOfferInCart
        ? (regularPrice - wholesalePrice) * quantity
        : (regularPrice - finalPrice) * quantity

    const pvTotal = showPv ? product?.pv * quantity : 0

    if (!product) return null
    return (
      <Container isCart={isCart} column={!isCart}>
        {showDiscount ? (
          <>
            <Container>
              <StyledSpan
                lineThru
                data-qa={`priceLineThru-${product.sku}`}
              >{`$${pricing.regularPrice}`}</StyledSpan>

              <StyledSpan
                bold
                data-qa={`priceDiscount-${product.sku}`}
              >{`$${pricing.finalPrice}`}</StyledSpan>
            </Container>
          </>
        ) : (
          <>
            <StyledSpan
              bold
              data-qa={`priceRegular-${product.sku}`}
            >{`$${pricing.regularPrice}`}</StyledSpan>
            {isOfferProduct && (
              <StyledSpan
                style={{ marginTop: '1em' }}
              >{`Expires: ${oneYearFromToday()}`}</StyledSpan>
            )}
          </>
        )}
        <Container column={!isCart}>
          {isCart && savings ? (
            <StyledSpan savings>{`(You save $${savings.toFixed(
              2
            )})`}</StyledSpan>
          ) : null}
          {showPv ? (
            <StyledSpan
              pv
              data-qa={`pv-${product.sku}`}
            >{`PV ${pvTotal}`}</StyledSpan>
          ) : null}
        </Container>
      </Container>
    )
  }

  return { ProductPricing }
}

export default useShowProductPrice
