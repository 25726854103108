export const getlanguageCountryMap = data => {
  const english = { languageCode: 'en', label: data?.english }
  const spanish = { languageCode: 'es', label: data?.spanish }
  const french = { languageCode: 'fr', label: data?.french }

  const usLanguages = [english, spanish]
  const auLanguages = [english]
  const nzLanguages = [english]
  const caLanguages = [english, french]

  return {
    US: usLanguages,
    AU: auLanguages,
    NZ: nzLanguages,
    CA: caLanguages,
  }
}
