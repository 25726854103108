import {
  GET_CARDS_ON_FILE,
  GET_ONE_TIME_NEXIO_TOKEN,
  NEXIO_POST_TRANSACTION,
  SAVE_CARD_TO_ASSOCIATE,
  PROCESS_PAYPAL_ORDER, NEXIO_PROCESS_TRANSACTION, NEXIO_CONFIRM_TRANSACTION
} from '../graphql/payments'

import {
  CreditCard,
  CreditCardsForAssociateResponse,
  NexioPostTransactionResponse,
  NexioProcessTransactionResponse,
  NexioPostTransData,
  NexioProcessTransData,
  OneTimeTokenResponse, NexioConfirmTransactionResponse, NexioConfirmTransData
} from '../../../types/PaymentsTypes'

export const Payments = client => ({
  getNexioOneTimeCode: async (): Promise<OneTimeTokenResponse> => {
    const {
      nexioOneTimeTokenIframe: { token },
    } = await client.request(GET_ONE_TIME_NEXIO_TOKEN)
    return token
  },
  getCardsOnFile: async (
    associateId: number
  ): Promise<CreditCardsForAssociateResponse> => {
    const { nexioCreditCardsForAssociate } = await client.request(
      GET_CARDS_ON_FILE,
      {
        associateId,
      }
    )
    return nexioCreditCardsForAssociate
  },
  nexioPostTransaction: async (
    data: NexioPostTransData
  ): Promise<NexioPostTransactionResponse> => {
    const { nexioPostTransaction } = await client.request(
      NEXIO_POST_TRANSACTION,
      data
    )
    return nexioPostTransaction
  },
  nexioProcessTransaction: async (
    data: NexioProcessTransData
  ): Promise<NexioProcessTransactionResponse> => {
    const { nexioProcessTransaction } = await client.request(
      NEXIO_PROCESS_TRANSACTION,
      data
    )
    return nexioProcessTransaction
  },
  nexioConfirmTransaction: async (
    data: NexioConfirmTransData, token?: string
  ): Promise<NexioConfirmTransactionResponse> => {
    if(token) {
      const { nexioConfirmTransaction } = await client.request(
        NEXIO_CONFIRM_TRANSACTION,
        data,
        {authorization: `Bearer ${token}`}
      )
      return nexioConfirmTransaction
    } else {
       const { nexioConfirmTransaction } = await client.request(
        NEXIO_CONFIRM_TRANSACTION,
        data
      )
      return nexioConfirmTransaction     
    }
  },
  saveCardData: async (associateId, cardData: CreditCard) =>
    await client.request(SAVE_CARD_TO_ASSOCIATE, { associateId, cardData }),
  processPayPalOrder: async data => {
    const result = await client.request(PROCESS_PAYPAL_ORDER, data)
    return result.processPayPalOrder.success
  },
})
