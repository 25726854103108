import React from 'react'
import { Icon } from 'semantic-ui-react'
import styled, { keyframes } from 'styled-components'
import { Container } from '..'

const Handle = styled.div<Partial<AnimatedContainerProps>>`
  display: flex;
  justify-content: center;
  color: ${props => props.color || props.theme.colors.metallicSeaweed};
  font-weight: 700;
  font-size: 14px;
  background-color: ${({ theme, open }) =>
    open ? `${theme.colors.white}` : 'transparent'};
  cursor: pointer;
  width: 100%;
  padding: 1rem;

  @media ${({ theme }) => theme.device.sm} {
    font-size: 18px;
  }
`
const initiate = keyframes`
 0% {  max-height: 0 }
 100% {   max-height: 0 }
`

const expand = keyframes`
 0% {  max-height: 0 }
 100% {   max-height: 350px }
`

const collapse = keyframes`
 0% {  max-height: 350px }
 100% { max-height: 0 }
`

interface AnimatedContainerProps {
  open: boolean
}

export const AnimatedContainer = styled.div<Partial<AnimatedContainerProps>>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  animation-name: ${props =>
    props.open === null ? initiate : props.open ? expand : collapse};
  animation-duration: 0.75s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  border-radius: 5px;
  box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.7);
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
`

export const Drawer = ({ title, open, onClick, children }) => {
  return (
    <Container direction="column" align="flex-start" padding="0 0.5rem">
      <Handle open={open} onClick={onClick}>
        <Container
          padding="0"
          width="auto"
          style={{
            backgroundColor: 'white',
            opacity: 0.8,
            borderRadius: '12px',
            paddingLeft: '6px',
          }}
        >
          {title}
          <Icon
            style={{ marginLeft: '1rem' }}
            name={open ? 'chevron up' : 'chevron down'}
          />
        </Container>
      </Handle>
      <AnimatedContainer open={open}>{children}</AnimatedContainer>
    </Container>
  )
}
