import {
  GET_ALL_PRODUCTS,
  GET_AVAILABLE_COUNTRIES,
  GET_CATEGORIES,
  GET_COUNTRY_DATA_BY_ID,
  GET_LANDING_PAGE_STORE_DATA,
  GET_SINGLE_CATEGORY_PRODUCTS,
  GET_SINGLE_PRODUCT,
  GET_SINGLE_PRODUCT_BY_SKU,
} from '../graphql/store'

export const Store = client => ({
  getAllProducts: async () => await client.request(GET_ALL_PRODUCTS),
  getAvailableCountries: async () =>
    await client.request(GET_AVAILABLE_COUNTRIES),
  getCategories: async () => await client.request(GET_CATEGORIES),
  getCountryData: async id => await client.request(GET_COUNTRY_DATA_BY_ID, id),
  getLandingPageData: async () =>
    await client.request(GET_LANDING_PAGE_STORE_DATA),
  getSingleCategoryData: async data =>
    await client.request(GET_SINGLE_CATEGORY_PRODUCTS, data),
  getSingleProductByUrlKey: async data =>
    await client.request(GET_SINGLE_PRODUCT, data),
  getSingleProductBySku: async data =>
    await client.request(GET_SINGLE_PRODUCT_BY_SKU, data),
})
