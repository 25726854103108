import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'semantic-ui-react'
import { usePrismic } from '../../context/PrismicContext'
import {
  Container,
  StyledButton,
  StyledModalContent,
  StyledText,
} from './Verification.styles'

const VerifyDeleteFirebaseUser = ({ open, onClose, yesOnClick }) => {
  const {
    prismicData: {
      prismicExistingQModal: { no: noText, yes: yesText },
    },
  } = usePrismic()
  return (
    <Modal
      closeIcon
      style={{ width: '80%', maxWidth: 300 }}
      open={open}
      centered={true}
      closeOnDimmerClick={true}
      closeOnEscape
      onClose={onClose}
    >
      <StyledModalContent>
        <StyledText>
          Closing this will delete your email address from our records and you
          will have to follow the enrollment link and sign up again. Are you
          sure you want to close?
        </StyledText>
        <Container justify="flex-end">
          <StyledButton onClick={onClose}>{noText[0].text}</StyledButton>
          <StyledButton onClick={yesOnClick}>{yesText[0].text}</StyledButton>
        </Container>
      </StyledModalContent>
    </Modal>
  )
}

VerifyDeleteFirebaseUser.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  yesOnClick: PropTypes.func.isRequired,
}

export default VerifyDeleteFirebaseUser
