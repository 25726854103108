import React from 'react'
import { navigate } from 'gatsby'
// Components
import { Seo, Primary, CountryDropdown } from '..'
// Context
import { useAuthContext } from '../../context/AuthContext'
import {
  Wrapper,
  Container,
  QLogo,
  PageHeader,
  PageComment,
  StyledText,
} from './EnrollmentForm.styles'

const EnrollmentCountries = () => {
  const {
    manageReferral: { referralData },
    selectedEnrollmentCountry,
    setSelectedEnrollmentCountry,
  } = useAuthContext()

  // TODO - add prismic
  return (
    <>
      <Seo title="Enrollment Form" />
      <Wrapper>
        <Container>
          <QLogo src="https://shopq.cdn.prismic.io/shopq/06ea17c4-0c65-4563-9507-bc314540e05f_black-q-sciences.svg" />
        </Container>
        <PageHeader>Ambassador Enrollment</PageHeader>
        {referralData?.ambassadorName && (
          <PageComment>{`(referred by ${referralData?.ambassadorName})`}</PageComment>
        )}
        <StyledText style={{ textAlign: 'left' }}>
          Select the country you reside in:
        </StyledText>
        <CountryDropdown
          value={selectedEnrollmentCountry}
          onChange={e => setSelectedEnrollmentCountry(e.target.value)}
        />
        <Container margin="3rem 0 0 0">
          <Primary
            disabled={!selectedEnrollmentCountry}
            onClick={() => navigate('/enrollment-form')}
          >
            Continue
          </Primary>
        </Container>
      </Wrapper>
    </>
  )
}

export default EnrollmentCountries
