import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
// Components
import { Modal, Radio } from 'semantic-ui-react'
import { Primary } from '../../components'
// Styles
import {
  StyledModal,
  StyledLogo,
  StyledHeader,
  Container,
  StyledText,
  StyledTextSmall,
  StyledCheck,
  Gap,
  Divider,
  StyledCheckbox,
  ErrorLabel,
  AnimatedContainer,
} from './RenewalModalForAmb.styles'
// Contexts
import { useAuthContext } from '../../context/AuthContext'
import { useCartContext } from '../../context/CartContext'
import { usePrismic } from '../../context/PrismicContext'
// Utils
import { enrollSkus } from '../../utils/constants'
import { getAmbRenewalFees } from '../../utils/storeHelpers'
import { formatParagraph } from '../../utils/prismicUtils'

const RenewalModalForAmb = ({ open, disableOnClose = false }) => {
  const { qUser, setShowRenewalModalForAmb } = useAuthContext()
  const {
    ambRenewalProducts,
    setAmbRenewalProducts,
    selectedAmbRenewalSku,
    setSelectedAmbRenewalSku,
    addSelectedAmbRenwalFeeToCart,
  } = useCartContext()

  const { ambRenewalPremiumSku, ambRenewalBasicSku } = enrollSkus
  const { premium, basic } = ambRenewalProducts

  const getRenewalFees = async () => {
    const { ambRenewalPremium, ambRenewalBasic } = await getAmbRenewalFees()
    setAmbRenewalProducts({
      premium: ambRenewalPremium,
      basic: ambRenewalBasic,
    })
  }

  useEffect(() => {
    getRenewalFees()
    if (enrollSkus) {
      setSelectedAmbRenewalSku(ambRenewalPremiumSku)
    }
  }, [enrollSkus])

  const skuPriceMap = {
    [ambRenewalPremiumSku]: premium,
    [ambRenewalBasicSku]: basic,
  }

  const totalCost =
    skuPriceMap[selectedAmbRenewalSku]?.products?.items[0]?.price?.regularPrice
      ?.amount?.value

  const onHandleSkuChange = name => setSelectedAmbRenewalSku(name)

  const {
    prismicData: {
      prismicCartPage: { understand_and_agree_policy },
      prismicPickYourPerksModal,
    },
  } = usePrismic()

  const onClose = () => setShowRenewalModalForAmb(false)

  const [areTermsAccepted, setAreTermsAccepted] = useState(false)
  const [error, setError] = useState(false)
  const onSubmit = () => {
    if (!areTermsAccepted) return setError(true)
    setShowRenewalModalForAmb(false)
    addSelectedAmbRenwalFeeToCart()
  }

  return (
    <StyledModal
      closeIcon={!disableOnClose}
      open={open}
      centered={true}
      closeOnDimmerClick={!disableOnClose}
      closeOnEscape={!disableOnClose}
      onClose={onClose}
    >
      <Container direction="column">
        <StyledLogo src="https://shopq.cdn.prismic.io/shopq/06ea17c4-0c65-4563-9507-bc314540e05f_black-q-sciences.svg" />
        <Gap height="16px" />
        <StyledText>
          {`${prismicPickYourPerksModal.welcome[0].text} ${
            qUser?.legalFirstName ?? ''
          } ${qUser?.legalLastName ?? ''}! ${
            prismicPickYourPerksModal.time_to_renew[0].text
          }`}
        </StyledText>
        <Gap height="16px" />
        <StyledHeader>
          {prismicPickYourPerksModal.pick_your_perks[0].text}
        </StyledHeader>
      </Container>
      <Modal.Content style={{ padding: 8 }}>
        <Container direction="column">
          <Container justify="flex-start">
            <Radio
              style={{ marginRight: 12 }}
              checked={selectedAmbRenewalSku === ambRenewalBasicSku}
              onChange={() => onHandleSkuChange(ambRenewalBasicSku)}
            />
            <StyledTextSmall
              weight={900}
              align="left"
              onClick={() => onHandleSkuChange(ambRenewalBasicSku)}
              style={{ cursor: 'pointer' }}
            >
              {prismicPickYourPerksModal.basic_ambassador[0].text}
            </StyledTextSmall>
          </Container>
          <Gap />

          <Container direction="column" style={{ paddingLeft: 16 }}>
            <Container justify="flex-start">
              <StyledCheck />
              <StyledTextSmall align="left">
                {prismicPickYourPerksModal.full_access_to_arq[0].text}
              </StyledTextSmall>
            </Container>
            <Gap />

            <Container justify="flex-start">
              <StyledCheck />
              <StyledTextSmall align="left">
                {prismicPickYourPerksModal.full_access_to_myqfit[0].text}
              </StyledTextSmall>
            </Container>
          </Container>
        </Container>
        <Gap height="12px" />

        <Container direction="column" padding="0">
          <Container justify="flex-start">
            <Radio
              style={{ marginRight: 12 }}
              checked={selectedAmbRenewalSku === ambRenewalPremiumSku}
              onChange={() => onHandleSkuChange(ambRenewalPremiumSku)}
            />
            <StyledTextSmall
              weight={900}
              align="left"
              onClick={() => onHandleSkuChange(ambRenewalPremiumSku)}
              style={{ cursor: 'pointer' }}
            >
              {prismicPickYourPerksModal.premium_ambassador[0].text}
            </StyledTextSmall>
          </Container>
          <Gap />
          <Container direction="column" style={{ paddingLeft: 16 }}>
            <Container justify="flex-start">
              <StyledCheck />
              <StyledTextSmall align="left">
                {prismicPickYourPerksModal.everything_in_basic[0].text}
              </StyledTextSmall>
            </Container>
            <Gap />

            <Container justify="flex-start">
              <StyledCheck />
              <StyledTextSmall weight={900} align="left">
                {
                  prismicPickYourPerksModal.free_shipping_on_subscriptions[0]
                    .text
                }
              </StyledTextSmall>
            </Container>
          </Container>
          <Gap height="12px" />
          {totalCost ? (
            <AnimatedContainer>
              <StyledText
                weight={900}
              >{`${prismicPickYourPerksModal.total[0].text}: $${totalCost}`}</StyledText>
            </AnimatedContainer>
          ) : (
            <Gap height="32px" />
          )}
          <Gap height="24px" />
          <Divider />
          <Container direction="column">
            {error && (
              <ErrorLabel>
                {prismicPickYourPerksModal.must_accept_terms[0].text}
              </ErrorLabel>
            )}
            <StyledCheckbox
              label={
                <label
                  dangerouslySetInnerHTML={{
                    __html: formatParagraph(understand_and_agree_policy[0]),
                  }}
                />
              }
              value="areTermsAccepted"
              checked={areTermsAccepted === true}
              onChange={() =>
                setAreTermsAccepted(prev => {
                  setError(false)
                  return !prev
                })
              }
            />
          </Container>
          <Gap height="24px" />
          <Primary
            disabled={error}
            onClick={onSubmit}
            content={`${'continue'.toUpperCase()}`}
          />
        </Container>
      </Modal.Content>
    </StyledModal>
  )
}

RenewalModalForAmb.propTypes = {
  open: PropTypes.bool.isRequired,
  disableOnClose: PropTypes.bool,
}

export default RenewalModalForAmb
