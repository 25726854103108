import styled from 'styled-components'
import { Link } from 'gatsby'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: ${props => (props.large ? '10px 0' : '1px 0')};
  width: 100%;
`

export const StyledLink = styled(Link)`
  &&& {
    color: ${({ theme }) => theme.colors.white};
    :active {
      color: ${({ theme }) => theme.colors.white};
    }

    ::before {
      display: none;
    }
  }
`

export const Title = styled.span`
  font-size: 1.3rem;
  font-weight: bold;
  align-self: center;
  margin: 5px;
  color: ${({ theme }) => theme.colors.white};
`

export const Span = styled.span`
  font-size: 1rem;
  padding-left: 5px;
`

export const FilterWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const FilterMenuItem = styled.span`
  cursor: pointer;
  margin: 4px 0;
  color: ${props => props.selected && props.theme.colors.metallicSeaweed};
`

export const BecomeAmbContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-end;
  padding: 0 8px;
`

export const FlagImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 8px;
`
